import mainjson from './main';
import { Level } from '../utils/LoginFunctions';

function checkIf(data) {
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

export default function GetData() {
        let data = {
        "apitype":"membertypes",
        "id":"member_type_id",
        "subidname":"member_type_name",
        "options":["edit","delete","insert"],
        "nameSingle":"member type",
        "nameMultiple":"member types",

        "extraoptions":[
            {
                "name":"Members",
                "page":"/members",
            },
        ],
      
        "fields":{
            "field1":{
                "name":"Name",
                "field":"member_type_name",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field5":{
                "name":"Client",
                "field":"member_type_client_id",
                "type":"DropDown",
                "options": "usergroups",
                "list":true,
                "if":checkIf
            }, 
            "field6":{
                "name":"Unsubscribable",
                "field":"member_type_unsubscribe",
                "type":"CheckBox",
                "list":true,
                "edit":true,
            }, 
            


        }
    }

    return data;
}